<template>
  <div>
    <vx-card>
      <div v-if="editAboutUs === false">
        <div class="header-table">
          <div class="w-full mb-8">
            <div class="flex flex-wrap items-center">
              <div class="w-full md:w-3/4">
                <div class="card-title">
                  <h2>{{ aboutUsData.title }}</h2>
                </div>
              </div>
              <div class="w-full md:w-1/4">
                <vs-button
                  color="primary"
                  type="filled"
                  @click="editAboutUs = true"
                  class="w-full md:w-auto float-right"
                >Edit
                </vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="fullwidth-banner">
          <img :src="aboutUsData.bannerPhoto" class="responsive">
        </div>

        <p class="w-full mt-5 mb-5 about-us" v-html="aboutUsData.description"></p>
        <!--        aboutUsData.websiteLink ?(aboutUsData.websiteLink.includes('http') ? aboutUsData.websiteLink : 'https://'+aboutUsData.websiteLink) :''-->
        <p class="h4 mt-5"><a
          :href="formattedUrl(aboutUsData.websiteLink) "
          target="_blank" class="underline">website</a></p>
      </div>

      <!-- edit about us-->
      <div v-else>
        <!-- title -->
        <div class="input-wrap w-full mb-10">
          <vs-input
            name="title"
            v-model="aboutUsData.title"
            v-validate="'required'"
            data-vv-validate-as="Title"
            label-placeholder="Title"
            class="w-1/2"
          />
          <span class="text-danger text-sm">{{ errors.first('title') }}</span>
        </div>
        <div class="mb-5">
          <!-- Image Container -->
          <div class="w-full mb-5">
            <img :src="aboutUsData.bannerPhoto" alt="img" class="responsive" style="height:400px">
          </div>

          <!-- Image upload Buttons -->
          <div class="w-full">
            <input
              type="file"
              class="hidden"
              name="file"
              ref="uploadImgInput"
              @change="updateImage($event.target.files)"
              accept="image/*"
            />
            <vs-button
              class="mb-5 vs-button-secondary"
              @click="$refs.uploadImgInput.click()"
              type="border"
            >Update photo
            </vs-button>
          </div>
        </div>

        <!-- description-->
        <div class="input-wrap w-full mb-10">
          <label class="ml-2">Description</label>
          <quillEditor :pages="description"></quillEditor>
          <span class="text-danger text-sm">{{ errors.first('description') }}</span>
        </div>

        <!--website link-->
        <div class="input-wrap w-full mb-10">
          <vs-input
            name="websiteLink"
            v-model="aboutUsData.websiteLink"
            v-validate="'required'"
            data-vv-validate-as="Website link"
            label-placeholder="Website link"
            class="w-1/2"
          />
          <span class="text-danger text-sm">{{ errors.first('websiteLink') }}</span>
        </div>

        <div class="w-full my-10 px-5" align="right">
          <vs-button :disabled="!isFormValid" @click="editHandler">Save</vs-button>
          <vs-button class="ml-5" color="danger" @click="editAboutUs = false">Cancel</vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import vSelect from "vue-select";
import quillEditor from "../../../Editor";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import {formattedUrl} from "../../../../helpers/general";

export default {
  components: {
    "v-select": vSelect,
    quillEditor
  },
  data() {
    return {
      aboutUsData: '',
      centerId: '',
      editAboutUs: false,
      description: {content: null},
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
        && this.aboutUsData.title
        && this.description.content
        && this.aboutUsData.websiteLink
        && this.aboutUsData.bannerPhoto
    }
  },
  methods: {
    ...mapActions("aboutUs", [
      "getAboutUsDetailsAdmin",
      "uploadImageAdmin",
      "updateAboutUsAdmin"
    ]),
    formattedUrl,
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color,
      });
    },
    getAboutUsDetails(id) {
      this.$vs.loading();
      this.getAboutUsDetailsAdmin(id).then((res) => {
        this.aboutUsData = res.data.data;
        this.description.content = this.aboutUsData.description;
        this.$vs.loading.close();
      })
    },
    async updateImage(file) {
      Swal.fire({
        title: "Are you sure you want to update the photo?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      }).then(async (result) => {
        if (result.value) {
          this.$vs.loading();
          let imageFile = file[0];
          if (file.length > 0) {
            let obj = new FormData();
            obj.append("file", imageFile);
            let data = {
              obj: obj,
              config: {
                header: {
                  "Content-Type": "image/png",
                },
              },
            };
            this.uploadImageAdmin(data)
              .then(async (res) => {
                let location = res.data.data;
                this.aboutUsData.bannerPhoto = await location;
                const info = {
                  id: this.aboutUsData._id,
                  data: {
                    bannerPhoto: this.aboutUsData.bannerPhoto,
                  },
                };
                this.updateAboutUsAdmin(info).then((res) => {
                  this.$vs.loading.close();
                  this.showMessage(
                    "Success",
                    "Photo updated successfully.",
                    "success"
                  );
                })
              })
          }
        }
      })
    },
    async editHandler() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          const info = {
            id: this.aboutUsData._id,
            data: {
              description: this.description.content,
              title: this.aboutUsData.title,
              bannerPhoto: this.aboutUsData.bannerPhoto,
              websiteLink: this.aboutUsData.websiteLink
            },
          };
          this.$vs.loading();
          this.updateAboutUsAdmin(info).then((res) => {
            this.editAboutUs = false;
            this.aboutUsData = res.data.data;
            this.description.content = this.aboutUsData.description;
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "About us details edited successfully.",
              "success"
            );
          });
        }
      })
    },
  },
  async created() {
    this.centerId = this.$route.params.id;
    this.getAboutUsDetails(this.centerId);
  }
}
</script>


